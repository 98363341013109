@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&family=Work+Sans&display=swap');

:root {
  --ganddee-neutral: #F5F0E7;
  --ganddee-disruptive-blue: #3065F1;
  --ganddee-community-blue: #A6D8EC;
  --ganddee-electric-yellow: #EBF233;
  --ganddee-energised-red: #FF360E;
  --ganddee-friendly-pink: #FF99C7;
}

html, body {
  font-family: 'Work Sans', sans-serif;
  margin: 0;
  padding: 0;
  overflow-y: auto;
}

.App {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.background-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--ganddee-disruptive-blue);
  z-index: 1;
}

.content-wrapper {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.App-header {
  background-color: rgba(255, 255, 255, 0); /* Semi-transparent background */
  padding: 10px;
  color: var(--ganddee-community-blue);
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.App-header a {
  display: inline-block; /* This ensures the link doesn't take up more space than necessary */
  text-decoration: none; /* This removes the underline from the link */
}

.App-header a:hover {
  opacity: 0.8; /* This creates a subtle hover effect */
}

.header-logo {
  max-width: 250px; /* Adjust this value as needed */
  height: auto;
  object-fit: contain;
  padding-top: 10px;
  filter: brightness(0) saturate(100%) invert(82%) sepia(19%) saturate(456%) hue-rotate(164deg) brightness(96%) contrast(90%);
}

/* Add a media query for responsiveness */
@media (max-width: 768px) {
  .header-logo {
    max-width: 150px; /* Adjust for smaller screens */
  }
}

h1 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 800;
  font-size: xx-large;
  margin: 10;
}

.main-content {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  overflow-y: auto;
}

/* Cards */

.card-container {
  position: relative;
  width: 90%;
  max-width: 700px;
  height: auto;
  aspect-ratio: 16 / 9;
  margin: 0 auto;
}

.card {
  position: absolute;
  width: 100%;
  height: 80%;
  background-color: rgba(255, 255, 255, 0.7); /* Semi-transparent white */
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px); /* For Safari support */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  box-sizing: border-box;
}

.card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.card h2 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 800;
  color: var(--ganddee-disruptive-blue);
  margin: 0 0 15px 0;
  text-align: center;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8em;
  line-height: 1.2;
  padding: 10px;
}

.button-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 15px;
}

button {
  font-family: 'Open Sans', sans-serif;
  font-weight: 800;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.1s;
  font-size: 1.2em;
  width: 100%;
  margin-bottom: 10px;
  white-space: normal;
  word-wrap: break-word;
}

button:last-child {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .card-container {
    aspect-ratio: 4 / 3;
  }

  .card h2 {
    font-size: 1.3em;
  }

  button {
    padding: 8px 20px;
    font-size: 1em;
  }
}

@media (max-width: 480px) {
  .card-container {
    aspect-ratio: 3 / 4;
    max-width: 95%;
  }

  .card h2 {
    font-size: 1.3em;
  }

  button {
    padding: 8px 15px;
    font-size: 1em;
  }
}

button:active {
  transform: scale(0.95);
}

.yes-button {
  background-color: var(--ganddee-community-blue);
  color: var(--ganddee-disruptive-blue);
}

.no-button {
  background-color: black;
  color: white;
}

/* Cards */

.explanation-container, .thank-you-container {
  background-color: rgba(255, 255, 255, 0.7); /* Semi-transparent white */
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px); /* For Safari support */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 700px;
  margin: 0 auto;
}

.explanation, .sustainability-tips {
  text-align: left;
}

.next-button, .restart-button {
  background-color: var(--ganddee-disruptive-blue);
  color: white;
  margin-top: 20px;
}

.sustainability-tips h3 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 800;
  color: var(--ganddee-disruptive-blue);
}

.sustainability-tips ul {
  padding-left: 20px;
}

.start-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 400px;
}

.start-card h2 {
  color: var(--ganddee-disruptive-blue);
  margin-bottom: 20px;
}

.start-card p {
  margin-bottom: 30px;
}

.start-button {
  background-color: var(--ganddee-electric-yellow);
  color: var(--ganddee-disruptive-blue);
  font-size: 1.2em;
  padding: 15px 30px;
}

footer {
  background-color: rgba(166, 216, 236, 0.9);
  color: var(--ganddee-disruptive-blue);
  padding: 10px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 800;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.social-icons {
  display: flex;
  gap: 10px;
}

.social-icon {
  width: 30px;
  height: 30px;
  filter: brightness(0) saturate(100%) invert(37%) sepia(74%) saturate(2794%) hue-rotate(214deg) brightness(98%) contrast(94%);
  transition: filter 0.3s ease;
}

.social-icon:hover {
  filter: brightness(0) saturate(100%) invert(37%) sepia(74%) saturate(2794%) hue-rotate(214deg) brightness(98%) contrast(94%) drop-shadow(0 0 2px rgba(0, 0, 0, 0.5));
}

.powered-by {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--ganddee-disruptive-blue);
  transition: opacity 0.3s ease;
}

.powered-by:hover {
  opacity: 0.8;
}

.ganddee-logo {
  height: 20px;
  margin-left: 5px;
}

canvas {
  pointer-events: none;
}